import styled from 'styled-components';


export const StickyBar = styled.div `
    position: fixed;
    will-change: transform;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: rgb(45, 45, 45);
    z-index: 9998;
    @media only screen and (min-device-width: 800px) {
        min-width: 1200px;
    }
`

export const Navigation = styled.div `
    display: inline-block;
    margin: 0 auto;
`

export const SocialLinks = styled.div `
    display: inline-block;
    position: fixed;
    will-change: transform;
    min-width: 100px;
    margin-left: 140px;
`

export const ImageStyled = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 5px;
`
interface LinkTextProps {
    color: string;
}

export const LinkText = styled.span `
    color: white;
    font-size: 25px;
    font-family: "Montserrat-Regular";
    font-weight: normal;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    &:hover {
        color: ${(props:LinkTextProps) => props.color};
    }
`

export const LinkItems = styled.div `
    min-width: 1000px;
    width: 60%;
    height: 100%;
    margin: 0 auto;
    display: none;
    @media only screen and (min-device-width: 800px) {
        min-width: 1000px;
        display: inline-block;
    }
`

export const BurgerMenu = styled.button `
    display: inline-block;
    background-color: rgb(45, 45, 45);
    border: 0;
    float: right;
    margin-top: 5px;
    @media only screen and (min-device-width: 800px) {
        min-width: 1000px;
        display: none;
    }
    outline: none;
`

export const BurgerImg = styled.img `
    width: 30px;
    height: 30px;
`

interface SideNavProps {
    width: string;
    borderWidth: string;
}

export const SideNav = styled.div `
    position: fixed;
    will-change: transform;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: ${(props:SideNavProps) => props.width};
    background-color: rgb(45,45,45);
    overflow-x: hidden;
    height: 100%;
    transition: 0.5s;
    font-family: "Montserrat-Regular";
    color: white;
    padding-top: 45%;
    border-right: ${(props:SideNavProps) => props.borderWidth};
`
export const SideNavLinkWrapper = styled.div `
    width: 200px;
    display: block;
    margin-bottom: -1px;
`
export const SideNavLink = styled.span `
    text-decoration: none;
    font-size: 25px;
    margin: 0 auto;
    display: block;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    width: 125px;

`

export const SideNavLogo = styled.img `
    display: block;
    max-width: 180px;
    display: block;
    margin-bottom: 30px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
`
