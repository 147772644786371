import styled from "styled-components";

export const DivRow = styled.div`
  font-size: 12px;
  font-family: sans-serif;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: inline-block;
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const StyledImageText = styled.div`
  color: rgb(255, 102, 242);
  font-size: 20px;
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 90px;
  @media only screen and (max-device-width: 800px) {
    margin-bottom: 50px;
  }
`;

export const StyledPhoneText = styled.span`
  position: absolute;
  will-change: transform;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 50px;
  @media only screen and (max-device-width: 800px) {
    margin-bottom: 20px;
  }
  font-size: 20px;
  color: rgb(255, 102, 242);
`;

export const StyledCallUs = styled.a`
  color: rgb(255, 255, 255);
`;
