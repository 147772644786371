import styled from "styled-components";

export const SocialWrapper = styled.div`
  width: 120px;
  height: 60px;
  margin: 0 auto;
  padding-top: 25px;
  width: 100%;
  max-width: 1200px;
  @media only screen and (max-device-width: 800px) {
    padding-top: 0px;
  }
`;

export const SocialItem = styled.img`
  margin: 5px;
`;

export const StyledWrapperRowDiv = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledEnquireDivWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
export const StyledEnquireNow = styled.a`
  background-color: rgb(254, 106, 241);
  padding: 8px;
  border-radius: 2px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  margin: 10px;
  text-decoration: none;
  font-family: "Montserrat-Regular";
  font-size: 20px;
  font-weight: bold;
  @media only screen and (max-device-width: 800px) {
    font-size: 16px;
  }
`;

export const StyledStore = styled.a`
  background-color: rgb(0, 255, 255);
  padding: 8px;
  border-radius: 2px;
  text-align: center;
  height: 20px;
  line-height: 20px;
  margin: 10px;
  text-decoration: none;
  font-family: "Montserrat-Regular";
  font-size: 20px;
  font-weight: bold;
  @media only screen and (max-device-width: 800px) {
    font-size: 16px;
  }
`;

export const StyledWrapperCol = styled.div<any>`
  width: 50%;
  display: flex;
`;
