import React, { Component } from "react";
import "./App.css";
import {
  Container,
  NavigationBar,
  ImageDescription,
  Logo,
  Services,
  Socials,
  ImageText,
  Facility,
  Map,
} from "./components";
import {
  Home,
  AboutUsContainer,
  ServicesContainer,
  FacilityContainer,
  FooterContainer,
} from "./containers";
import content from "./data/content.json";
import theman from "./assets/strengthstore4.jpg";
import facilityImage from "./assets/strengthstore1.jpg";
import strengthlogovariant from "./assets/strengthlogonew.png";

class App extends Component {
  render() {
    return (
      <Container>
        <NavigationBar />
        <Home>
          <Logo />
          <Socials />
        </Home>
        <AboutUsContainer>
          <ImageDescription
            heading={content.aboutus.heading}
            description={content.aboutus.description}
            image={theman}
          />
        </AboutUsContainer>
        <ServicesContainer>
          <Services />
        </ServicesContainer>
        <FacilityContainer>
          <Facility image={facilityImage} />
        </FacilityContainer>
        <Map />
        <FooterContainer>
          <ImageText image={strengthlogovariant} />
        </FooterContainer>
      </Container>
    );
  }
}

export default App;
