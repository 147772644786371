import styled from 'styled-components';

interface ContainerProps {
    background: any
}

export const AdDesktopStyled = styled.img `
    display: none;
    max-width: 1200px;
    @media only screen and (min-device-width: 800px) {
        display: block;
        margin: 0 auto;
    }
    margin-bottom: -5px;
`
export const AdMobileStyled = styled.img ` 
    @media only screen and (min-device-width: 800px) {
        display: none;
    }
    width: 100%;
    margin-bottom: -5px;
`