import React from "react";

export default function ContactWidget() {
  return (
    <>
      <div
        class="wl-widget"
        data="k_skin=190432&amp;url_page=fo_WAFOJdZ17nzaWVHGdfLnRV_8%3Ahttps%3A%2F%2Fthestrengthstore.com.au%2F"
      ></div>
      <script
        src="https://www.wellnessliving.com/rs/skin-widget-static.js"
        type="text/javascript"
      ></script>

      <a
        href="https://www.wellnessliving.com/fitness/"
        rel="nofollow"
        style={{ float: "right" }}
      >
        <img
          src="https://s3.amazonaws.com/wl-static/file/J.png"
          alt="Fitness management software"
          height="39"
          width="135"
        />
      </a>

      <br clear="both" />
    </>
  );
}
