import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const StyledReactPlayer = styled(ReactPlayer) `
    border: 0;
    outline: none;
`

export const DivRow = styled.div `
    font-size: 12px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: inline-block;
    background-color: rgb(255,255,255);
    @media only screen and (min-device-width: 800px) {
        min-width: 1000px;
    }

`

export const AboutUsDiv = styled.div `
    display: inline-block;
    width: 100%;
    max-width: 1100px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    vertical-align: top;
    line-height: 1.5;
    @media only screen and (max-device-width: 800px) {
        display: block;
        margin: auto;
        width: 100%;
        text-align: center;
    }
`

export const AboutUsImage = styled.img `
    width: 60%;
    max-width: 565px;
    padding-bottom: 15px;
    @media only screen and (max-device-width: 800px) {
        display: block;
        margin: 0 auto;
        padding-top: 5px;
        width: 100%;
    }
`
export const StyledH2 = styled.h2 `
    font-weight: normal;
    font-size: 60px;
    font-family: 'League Gothic';
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(254, 106, 241);
    @media only screen and (max-device-width: 800px) {
        font-size: 40px;
        padding-top: 5px;
        clear: left;
    }
`

export const StyledDescription = styled.h2 `
    @media only screen and (max-device-width: 800px) {
        font-size: 14px;
    }
    color: rgb(71,71,71);
    display: inline-block;
    font-weight: normal;
    font-family: "Montserrat-Regular";
`
