import React from 'react';
import strengthlogodesktop from '../../assets/strengthbannernew2.png';
import strengthlogomobile from '../../assets/strengthbannernewmobile2.png';
import { StyledLogo, StyledLogoMobile, LogoWrapper } from './Logo.styled';

export default function Logo() {
    return (
        <LogoWrapper>
            <StyledLogo src={strengthlogodesktop} alt="Strength Store" />
            <StyledLogoMobile src={strengthlogomobile} alt="Strength Store" />
        </LogoWrapper>
    )
}