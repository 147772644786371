import React from 'react';
import { MapWrapper } from './Map.styled';
import { StyledDesktopIFrame, StyledMobileIFrame} from '../map/Map.styled';

export default function Logo() {
    return (
        <MapWrapper>
            <StyledDesktopIFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.243105088693!2d144.91948571584396!3d-37.784342140134505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d014c620fd5%3A0x46092b5fac18c381!2sUnit%201%2F27%20Ascot%20Vale%20Rd%2C%20Flemington%20VIC%203031!5e0!3m2!1sen!2sau!4v1610953018346!5m2!1sen!2sau" frameBorder="0"/>
            <StyledMobileIFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.243105088693!2d144.91948571584396!3d-37.784342140134505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d014c620fd5%3A0x46092b5fac18c381!2sUnit%201%2F27%20Ascot%20Vale%20Rd%2C%20Flemington%20VIC%203031!5e0!3m2!1sen!2sau!4v1610953018346!5m2!1sen!2sau" frameBorder="0"/>
        </MapWrapper>
    )
}