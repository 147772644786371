import React, { useEffect } from "react";
import {
  SocialWrapper,
  SocialItem,
  StyledEnquireNow,
  StyledStore,
  StyledWrapperRowDiv,
  StyledWrapperCol,
  StyledEnquireDivWrapper,
} from "./Socials.styled";
import facey from "../../assets/facebook.png";
import insta from "../../assets/insta2.png";

export default function Socials() {
  useEffect(() => {
    const setMoreScript = document.createElement("script");
    setMoreScript.src =
      "https://my.setmore.com/webapp/js/src/others/setmore_iframe.js";
    setMoreScript.id = "setmore_script";
    document.body.appendChild(setMoreScript);
  }, []);

  return (
    <>
      <SocialWrapper>
        <a href="https://www.facebook.com/thestrengthstoreau">
          <SocialItem src={facey}></SocialItem>
        </a>
        <a href="https://www.instagram.com/thestrengthstore/">
          <SocialItem src={insta}></SocialItem>
        </a>
      </SocialWrapper>
      <StyledWrapperRowDiv>
        <StyledWrapperCol>
          {" "}
          <StyledEnquireDivWrapper>
            <StyledEnquireNow href="https://www.wellnessliving.com/rs/lead-add.html?k_skin=190432">
              ENQUIRE NOW
            </StyledEnquireNow>
          </StyledEnquireDivWrapper>
        </StyledWrapperCol>

        <StyledWrapperCol>
          {" "}
          <StyledStore href="https://www.wellnessliving.com/rs/catalog-list.html?k_skin=186678&k_business=371942">
            GO TO STORE
          </StyledStore>
        </StyledWrapperCol>
      </StyledWrapperRowDiv>
    </>
  );
}
