import React from "react";
import "../../App.css";
import myVideo from "../../assets/sessions.mp4";
import {
  DivRow,
  AboutUsDiv,
  StyledH2,
  StyledDescription,
  StyledReactPlayer,
} from "./imagedescription.styled";

export default function AboutUs(props: any) {
  const { heading, description } = props;
  return (
    <DivRow>
      {/* // <AboutUsImage src={image} /> */}
      <StyledReactPlayer
        url={myVideo}
        width="100%"
        height="100%"
        controls={true}
        playing={true}
        loop={true}
      />
      <StyledH2>{heading}</StyledH2>
      <AboutUsDiv>
        <StyledDescription>{description}</StyledDescription>
      </AboutUsDiv>
    </DivRow>
  );
}
