import React from 'react';
import  { AboutUsContainer } from './Facility.styled';

interface HomeProps {};

export default function Home(props:React.PropsWithChildren<HomeProps>) {
    return(
        <AboutUsContainer id="facility">
                {props.children}
        </AboutUsContainer>
    )
}