import styled from 'styled-components';

interface ContainerProps {
    background: any
}

export const BannerStyled = styled.div `
    width: 100%
    margin-top: 0px;
    position: absolute;
    left: 0; right: 0; bottom: 0;
    margin-bottom: 25px;
    font-family: sans-serif;
    background-image: url(${(props:ContainerProps) => props.background});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`

export const ComingSoonStyled = styled.p `
    color: black;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: calc(7px + 2vmin);
    margin-top: 0;
    max-width: 1200px;
    @media only screen and (min-device-width: 800px) {
        min-width: 1200px;
        font-size: 35px;
    }
    font-weight: bold;
    font-style: italic;
    margin: 0 auto;
`