import React, { useEffect } from "react";
import { AdMobileStyled } from "../ad/Ad.styled";
import {
  DivRow,
  Service,
  StyledH2,
  StyledH3,
  Price,
  Service2,
  StyledClassesDiv,
  StyledH2Classes,
  StyledClassesBanner,
  StyledClassesBannerDiv,
  StyledPriceContainer,
  StyledStore,
  StyledStoreWrapper,
} from "./Services.styled";
import ClassesWidget from "./classesWidget";
import scheduleAd from "../../assets/scheduling.jpeg";
import yoga from "../../assets/yoga.jpg";
import gym from "../../assets/gym.png";

export default function Services() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.wellnessliving.com/rs/skin-widget-static.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <DivRow>
      <StyledClassesBannerDiv>
        <StyledClassesBanner src={gym} />
      </StyledClassesBannerDiv>
      <StyledH2>SERVICES</StyledH2>
      <Service>
        <StyledH3>COACHING</StyledH3>
        Our coaches have a wealth of experience in helping people exceed their
        goals. Using science based methods focused on correct technique through
        all movements, we are able to build maximum strength and movement
        efficiency still maintaining a friendly and enjoyable environment. The
        coaches will make sure your needs are met every step of the way to a
        stronger you
      </Service>
      <Service>
        <StyledH3>EXERCISE REHAB</StyledH3>
        Our resident exercise physiologist provides structured and safe training
        programs to those with chronic physiological conditions and health
        concerns, with outcomes aimed to return to normal life.
      </Service>
      <Service>
        <StyledH3>ONLINE PROGRAMMING</StyledH3>
        Our online programming is tailored to each individuals goals, abilities
        and available equipment to ensure they achieve the best results
        possible. Our coaches are here for the journey with you, monitoring your
        programs and offering advice and answering any questions you have to
        help you get the most out of your training.
      </Service>
      <AdMobileStyled src={scheduleAd} />
      <StyledClassesDiv>
        <StyledClassesBannerDiv>
          <StyledClassesBanner src={yoga} />
        </StyledClassesBannerDiv>
        <StyledH2Classes>CLASSES</StyledH2Classes>
        <ClassesWidget />
      </StyledClassesDiv>
      <br />
      <StyledH2>PRICING</StyledH2>
      <Service2>
        <StyledH3>TRAINING</StyledH3>
        <StyledPriceContainer>
          Intro Pack (3 x 1 Hour)..... <Price>$99</Price>
        </StyledPriceContainer>
        <StyledPriceContainer>
          10 pack (10 x 1 Hour).............<Price>$750</Price>
        </StyledPriceContainer>
        1:1 Session (1 Hour).............. <Price>$80</Price>
        <br />
        2:1 Session (1 Hour).............. <Price>$90</Price>
        <br />
      </Service2>
      <Service2>
        <StyledH3>PROGRAMMING</StyledH3>
        <StyledPriceContainer>
          Online Programming Per Week..............<Price>$20</Price>
        </StyledPriceContainer>
      </Service2>
      <Service2>
        <StyledH3>CLASSES</StyledH3>
        <StyledPriceContainer>
          Yoga..............<Price>$15</Price>
        </StyledPriceContainer>
        <StyledPriceContainer>
          Pilates..............<Price>$15</Price>
        </StyledPriceContainer>
      </Service2>
      <StyledStoreWrapper>
        <StyledStore href="https://www.wellnessliving.com/rs/catalog-list.html?k_skin=186678&k_business=371942">
          GO TO STORE
        </StyledStore>
      </StyledStoreWrapper>
    </DivRow>
  );
}
