import React, {useState} from 'react';
import {StickyBar, LinkText, LinkItems, SideNav, SideNavLink, BurgerMenu, BurgerImg, SideNavLogo, SideNavLinkWrapper} from './NavigationBar.styled';
import { Link } from "react-scroll";
import burger from '../../assets/burgerMenu.png';
import navbarlogo from '../../assets/navbarlogo.png';

import close from '../../assets/close.png';
import '../../App.css';

export default function NavigationBar() {
    const [burgerState, setBurgerState] = useState({img: burger, active: false});
    const [sideNavState, setSideNavState] = useState('0px');
    const [borderWidth, setBorderWidth] = useState('0px solid white');
    const burgerOnClick = () => {
        if(!burgerState.active) {
            setBurgerState({img: close, active: true});
            setSideNavState('200px');
            setBorderWidth('1px solid white')
        } else {
            setBurgerState({img: burger, active: false});
            setSideNavState('0px');
            setBorderWidth('0px solid white')
        }
    }
    const onSideNavigation = () => {
            setBurgerState({img: burger, active: false});
            setSideNavState('0px');
            setBorderWidth('0px solid white')
    }

    return (
        <>
        <StickyBar>
            <BurgerMenu>
                <BurgerImg src={burgerState.img} onClick={burgerOnClick}></BurgerImg>
            </BurgerMenu>
            <LinkItems>     
                <LinkText color={"rgb(141, 233, 255)"}><Link to="home" spy={true} smooth={true} offset={-70} duration= {500}>HOME</Link></LinkText>
                <LinkText color={"rgb(255, 102, 242)"}><Link to="about" spy={true} smooth={true} offset={-50} duration= {500}>ABOUT US</Link></LinkText>
                <LinkText color={"rgb(141, 233, 255)"}><Link to="services" spy={true} smooth={true} offset={-50} duration= {500}>SERVICES</Link></LinkText>
                <LinkText color={"rgb(255, 102, 242)"}><Link to="facility" spy={true} smooth={true} offset={-50} duration= {500}>FACILITY</Link></LinkText>
            </LinkItems>
        </StickyBar>
        <SideNav width={sideNavState} borderWidth={borderWidth}>
            <SideNavLogo src={navbarlogo}></SideNavLogo>
            <SideNavLinkWrapper>    
                <SideNavLink><Link to="home" onClick={onSideNavigation} spy={true} smooth={true} offset={-70} duration= {500}>HOME</Link></SideNavLink>
            </SideNavLinkWrapper>
            <SideNavLinkWrapper>  
                <SideNavLink><Link to="about" onClick={onSideNavigation} spy={true} smooth={true} offset={-50} duration= {500}>ABOUT US</Link></SideNavLink>
            </SideNavLinkWrapper>  
            <SideNavLinkWrapper>  
                <SideNavLink><Link to="services" onClick={onSideNavigation} spy={true} smooth={true} offset={-50} duration= {500}>SERVICES</Link></SideNavLink>
            </SideNavLinkWrapper>  
            <SideNavLinkWrapper> 
                <SideNavLink><Link to="facility" onClick={onSideNavigation} spy={true} smooth={true} offset={-50} duration= {500}>FACILITY</Link></SideNavLink>
            </SideNavLinkWrapper>  
        </SideNav>
    </>
    );
}