import React from "react";
import { ServicesContainer } from "./Services.styled";

interface ServicesProps {}

export default function services(
  props: React.PropsWithChildren<ServicesProps>
) {
  return <ServicesContainer id="services">{props.children}</ServicesContainer>;
}
