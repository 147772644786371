import styled from "styled-components";

interface AdProps {
  desktopAd: any;
  mobileAd: any;
}

interface ContainerProps {
  background: any;
}
export const DivRow = styled.div`
  font-size: 18px;
  display: inline-block;
  padding-bottom: 30px;
  background-color: rgb(30, 30, 30);
  max-width: 1200px;
  @media only screen and (max-device-width: 800px) {
    font-size: 12px;
  }
  font-family: "Montserrat-Regular";
`;

export const StyledClassesDiv = styled.div`
  font-size: 18px;
  display: inline-block;
  padding-bottom: 50px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  max-width: 1200px;
  @media only screen and (max-device-width: 800px) {
    font-size: 12px;
  }
  font-family: "Montserrat-Regular";
`;

export const AdDesktopStyled = styled.img`
  display: none;
  max-width: 1200px;
  @media only screen and (min-device-width: 800px) {
    display: block;
    margin: 0 auto;
  }
  margin-bottom: -5px;
`;
export const AdMobileStyled = styled.img`
  @media only screen and (min-device-width: 800px) {
    display: none;
  }
  width: 100%;
`;

export const Service = styled.div`
  margin-top: 0;
  display: inline-block;
  width: 370px;
  padding: 10px;
  vertical-align: top;
  line-height: 1.2;
  color: rgb(235, 235, 235);
  padding-bottom: 40px;
  @media only screen and (max-device-width: 800px) {
    padding-bottom: 0px;
    font-size: 14px;
    margin-bottom: 10px;
    width: 80%;
  }
`;

export const Class = styled.div`
  margin-top: 0;
  display: inline-block;
  width: 370px;
  padding: 10px;
  vertical-align: top;
  line-height: 1.2;
  color: rgb(71, 71, 71);
  @media only screen and (max-device-width: 800px) {
    font-size: 14px;
    margin-bottom: 10px;
    width: 80%;
  }
`;

export const Service2 = styled.div`
  margin-top: 0;
  display: inline-block;
  width: 400px;
  vertical-align: top;
  line-height: 1.5;
  color: rgb(235, 235, 235);
  font-size: 18px;
  @media only screen and (max-device-width: 800px) {
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
  }
`;
export const StyledH2 = styled.h2`
  font-weight: normal;
  font-size: 60px;
  font-family: "League Gothic";
  color: rgb(0, 255, 255);
  margin-top: 10px;
  margin-bottom: 10px;
  @media only screen and (max-device-width: 800px) {
    font-size: 40px;
  }
`;

export const StyledH2Classes = styled.h2`
  font-weight: normal;
  font-size: 60px;
  font-family: "League Gothic";
  color: rgb(254, 106, 241);
  margin-top: 10px;
  margin-bottom: 10px;
  @media only screen and (max-device-width: 800px) {
    font-size: 40px;
  }
`;

export const StyledH3 = styled.h3`
  font-weight: bold;
  font-size: 22px;
  color: rgb(255, 255, 255);
  @media only screen and (max-device-width: 800px) {
    font-size: 16px;
  }
`;

export const StyledH3Classes = styled.h3`
  font-weight: bold;
  font-size: 22px;
  color: rgb(71, 71, 71);
  @media only screen and (max-device-width: 800px) {
    font-size: 16px;
  }
`;

export const Price = styled.span`
  margin-left: 10px;
`;

export const StyledClassesBanner = styled.img`
  max-width: 1200px;
  width: 100%;
`;

export const StyledClassesBannerDiv = styled.div`
  max-width: 1200px;
  width: 100%;
  max-height: 600px;

  overflow: hidden;
`;

export const StyledPriceContainer = styled.div`
  width: 100%;
  white-space: nowrap;
`;
export const StyledSetMoreImage = styled.img`
  padding-top: 20px;
`;

export const StyledStoreWrapper = styled.div`
  padding: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
`;

export const StyledStore = styled.a`
  background-color: rgb(0, 255, 255);
  padding: 8px;
  border-radius: 2px;
  text-align: center;
  height: 20px;
  text-decoration: none;
  font-family: "Montserrat-Regular";
  font-size: 20px;
  font-weight: bold;
  @media only screen and (max-device-width: 800px) {
    font-size: 16px;
  }
`;
