import styled from "styled-components";

export const StyledLogo = styled.img`
  width: 100%;
  display: none;
  @media only screen and (min-device-width: 801px) {
    display: block;
  }
`;

export const StyledLogoMobile = styled.img`
  width: 100%;
  display: none;
  @media only screen and (max-device-width: 800px) {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  opacity: 0.95;
  max-width: 1200px;
  position: relative;
  @media only screen and (max-device-width: 800px) {
    padding-top: 100px;
    padding-bottom: 25px;
  }
`;
