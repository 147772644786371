import React from 'react';
import {StyledContainer, StyledBackground} from './Container.styled';
import background from '../../assets/backgroundstore.png';

interface MyProps {
    background: string
};

export default function Container(props: React.PropsWithChildren<MyProps>) {
    return(
        <StyledContainer background={background}>
            <StyledBackground>
             {props.children}
            </StyledBackground>
        </StyledContainer>
    )
}