import React from "react";
import "../../App.css";
import {
  DivRow,
  StyledCallUs,
  StyledImage,
  StyledImageText,
  StyledPhoneText,
} from "./ImageText.styled";

export default function ImageText(props: any) {
  const { image } = props;
  return (
    <DivRow>
      <StyledImage src={image} />
      <StyledImageText className="hiragino">
        info@thestrengthstore.com.au
      </StyledImageText>
      <StyledPhoneText>
        Call us on:{" "}
        <StyledCallUs href="tel:0437468786">0437 468 786</StyledCallUs>
      </StyledPhoneText>
    </DivRow>
  );
}
