import styled from 'styled-components';

export const MapWrapper = styled.div `
    margin-top: -1px;
    max-width: 1200px;
    margin: 0 auto;
`

export const StyledDesktopIFrame = styled.iframe `


    border: 0;
    margin-top: -1px;
    margin-bottom: -3px;
    @media only screen and (min-device-width: 800px) {
        display: block;
        width: 1200px;
        height: 450px;
    }
    @media only screen and (max-device-width: 800px) {
        display: none;
    }
`
export const StyledMobileIFrame = styled.iframe `
    border: 0;
    @media only screen and (min-device-width: 800px) {
        display: none;
    }
    @media only screen and (max-device-width: 800px) {
        display: block;
        width: 100%;
    }
`