import styled from 'styled-components';

export const DivRow = styled.div `
    font-size: 12px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: inline-block;
    background-color: rgb(255,255,255);
    @media only screen and (min-device-width: 800px) {
        min-width: 1200px;
    }
    font-family: "Montserrat-Regular";
`

export const AboutUsDiv = styled.div `
    display: inline-block;
    width: 46%;
    max-width: 600px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    vertical-align: top;
    line-height: 1.5;
    @media only screen and (max-device-width: 800px) {
        display: block;
        margin: auto;
        width: 90%;
        text-align: center;
    }
`

export const AboutUsImage = styled.img `
    width: 60%;
    max-width: 565px;
    padding-bottom: 15px;
    @media only screen and (max-device-width: 800px) {
        display: block;
        margin: 0 auto;
        padding-top: 5px;
        width: 100%;
    }
`
export const StyledH2 = styled.h2 `
    font-weight: normal;
    font-size: 60px;
    font-family: 'League Gothic';
    color: rgb(254, 106, 241);
    margin-top: 10px;
    margin-bottom: 10px;
    @media only screen and (max-device-width: 800px) {
        font-size: 40px;
        clear: left;
    }
`

export const StyledDescription = styled.h2 `
    @media only screen and (max-device-width: 800px) {
        font-size: 14px;
    }
    color: rgb(71,71,71);
    display: inline-block;
    font-weight: normal;
`

export const StyledAddress = styled.span `
    font-size: 25px;
    font-weight: bold;
    color: rgb(35, 35, 35);
    display: inline-block;
    margin-top: 100px;
    @media only screen and (max-device-width: 800px) {
        font-size: 18px;
        margin-top: 10px;
    }
`
