import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  position: relative;
  @media only screen and (min-device-width: 800px) {
    min-width: 1200px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const StyledHeadingRow = styled.div`
  background-color: white;
  width: 100%;
  margin-bottom: 0px;
`;

export const StyledH2 = styled.h2`
  font-weight: normal;
  font-size: 60px;
  font-family: "League Gothic";
  margin-top: 10px;
  margin-bottom: -5px;
  color: rgb(254, 106, 241);
  @media only screen and (max-device-width: 800px) {
    font-size: 40px;
    padding-top: 5px;
    clear: left;
  }
`;
