import React from 'react';
import  { HomeContainer } from './Home.styled';

interface HomeProps {};

export default function Home(props:React.PropsWithChildren<HomeProps>) {
    return(
        <HomeContainer id="home">
            {props.children}
        </HomeContainer>
    )
}