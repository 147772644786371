import styled from 'styled-components';


export const ServicesContainer = styled.div `
    width: 100%;
    position: relative;
    @media only screen and (min-device-width: 800px) {
        min-width: 1200px;
    }
    text-align: center;
    margin: 0 auto;
`