import React from 'react';
import  { AboutUsContainer } from './AboutUs.styled';

interface HomeProps {};

export default function Home(props:React.PropsWithChildren<HomeProps>) {
    return(
        <AboutUsContainer id="about">
                {props.children}
        </AboutUsContainer>
    )
}