import React, { useEffect } from "react";
import {
  FooterContainer,
  ContentWrapper,
  StyledHeadingRow,
  StyledH2,
} from "./Footer.styled";
import ContactUs from "./contactUs";

interface HomeProps {}

export default function Home(props: React.PropsWithChildren<HomeProps>) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.wellnessliving.com/rs/skin-widget-static.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <FooterContainer id="about">
        <ContentWrapper>{props.children}</ContentWrapper>
        <ContentWrapper></ContentWrapper>
      </FooterContainer>
      <StyledHeadingRow>
        {" "}
        <StyledH2>GET IN TOUCH</StyledH2>
      </StyledHeadingRow>
      <ContactUs />
    </>
  );
}
