import React from 'react';
import '../../App.css';
import { DivRow, AboutUsDiv, AboutUsImage, StyledH2, StyledDescription, StyledAddress } from './Facility.styled';

export default function AboutUs(props: any) {
    const {
        image
    } = props;
    return (
        <DivRow>
                            <StyledH2>OUR FACILITY</StyledH2>
            <AboutUsImage src={image} />
            <AboutUsDiv>
                <StyledDescription>
                Our two storey facility is located in the heart of Flemington. With a large range of quality specialist equipment with toilet and shower facilities available, our studio has been designed to match our high standard of coaching and deliver the best training experience possible. 
                <StyledAddress>1/27 Ascot Vale Rd, Flemington VIC 3031</StyledAddress>
                </StyledDescription>
            </AboutUsDiv>    
        </DivRow>
    );
};
