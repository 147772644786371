import styled from 'styled-components';

interface ContainerProps {
    background: any
}

export const StyledContainer = styled.div `
    text-align: center;
    background-image: url(${(props:ContainerProps) => props.background});
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    margin: 0;
    padding: 0;
    @media only screen and (min-device-width: 800px) {
        background-size:cover;
        background-position: left center;
        background-attachment: fixed;
        min-width: 1200px;
        width: 100%;
    }
    @media only screen and (max-device-width: 800px) {
        background-size: auto auto;
        background-position: top center;
        background-repeat: repeat;
    }
`

export const StyledBackground = styled.div `
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.3);
`